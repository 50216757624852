@import "assets/styles/variables";


#root {
  scroll-behavior: smooth !important;
}

#home-page {

  // -------------------------------
  .school {
    width: 50%;
    height: auto;
    z-index: 12;

    @media only screen and (max-width: 575px) {
      width: 100%;
      height: 100%;
    }
  }


  // -------------------------------
  .title-under-about {
    font-size: 32px;

    @media only screen and (max-width: 991px) {
      font-size: 27px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 575px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  .subtitle-under-about {
    font-size: 39px;

    @media only screen and (max-width: 991px) {
      font-size: 35px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 575px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 425px) {
      font-size: 16px;
    }
  }

  // ------------ Top Section-----------------

  .warrap-top {
    width: 100px;
    height: 100px;
    transform: scaleX(1);
    right: 0px;
    position: absolute;
    top: 10px;
  }

  .warrap-details-top {
    width: 100%;
    height: 100%;
    animation: circle1 5s infinite linear;
    transform-origin: 50% 50%;
  }

  .warrap-div-top {
    width: 80px;
    height: 80px;
    animation: ccircle1 5s infinite linear;
  }

  .twist-animation-top {
    width: 300px;
    height: 100px;
    position: absolute;
    left: -600px;
    bottom: -250px;
    display: block;
    transform: scaleX(0.33);

    @media only screen and (max-width: 1399px) {
      left: -530px;
      bottom: -200px;
    }
    
    @media only screen and (max-width: 1199px) {
      left: -510px;
      bottom: -210px;
    }

    @media only screen and (max-width: 991px) {
      left: -400px;
      bottom: -158px;
    }

    @media only screen and (max-width: 767px) {
      left: -500px;
      bottom: -200px;
    }

    @media only screen and (max-width: 500px) {
      left: -420px;
      bottom: -200px;
    }

    @media only screen and (max-width: 430px) {
      left: -380px;
      bottom: -180px;
    }

    @media only screen and (max-width: 425px) {
      left: -330px;
      bottom: -150px;
      width: 250px;
      height: 80px;
    }

    @media only screen and (max-width: 375px) {
      left: -310px;
      bottom: -150px;
      width: 250px;
      height: 80px;
    }

    @media only screen and (max-width: 350px) {
      left: -290px;
      bottom: -120px;
      width: 250px;
      height: 80px;
    }
  }

  @keyframes circle1 {
    from {
      transform: rotateZ(0deg)
    }

    to {
      transform: rotateZ(360deg)
    }
  }

  @keyframes ccircle1 {
    from {
      transform: rotateZ(360deg)
    }

    to {
      transform: rotateZ(0deg)
    }
  }

  // ------------Right Image------------------

  .right-circle {
    // style={{ position: 'absolute', bottom: -50, right: -250, zIndex:"-1" }}
    position: absolute;
    bottom: -50px;
    right: -250px;
    z-index: -1;

    // &:lang(ar) {
    //   left: -250px;
    //   right: auto;
    // }

    @media only screen and (max-width: 1399px) {
      bottom: -100px;
      right: -180px;

      // &:lang(ar) {
      //   left: -180px;
      //   right: auto;
      // }
    }

    @media only screen and (max-width: 1199px) {
      bottom: -50px;
      right: -180px;

      // &:lang(ar) {
      //   left: -150px;
      //   right: auto;
      // }
    }

    @media only screen and (max-width: 991px) {
      bottom: -80px;
      right: -170px;

      // &:lang(ar) {
      //   left: -120px;
      //   right: auto;
      // }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }


  .right-circle-div {
    // style={{ position: 'relative', width: '500px', height: '500px', borderRadius: '50%', backgroundColor: '#899bdb', }}

    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: theme-color('primary');

    @media only screen and (max-width: 1399px) {
      width: 450px;
      height: 450px;
    }

    @media only screen and (max-width: 1199px) {
      width: 400px;
      height: 400px;
    }

    @media only screen and (max-width: 991px) {
      width: 300px;
      height: 300px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .right-circle-details {
    // style={{ position: 'absolute', width: '400px', height: '400px', borderRadius: '50%', backgroundColor: 'white', top: '50px', left: '50px' }}
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: white;
    top: 50px;
    left: 50px;

    @media only screen and (max-width: 1399px) {
      width: 350px;
      height: 350px;
    }

    @media only screen and (max-width: 1199px) {
      width: 300px;
      height: 300px;
    }

    @media only screen and (max-width: 991px) {
      width: 200px;
      height: 200px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }


  // ------------Left Image-------------------

  .left-circle {
    // style={{ position: 'absolute', bottom: -50, right: -250, zIndex:"-1" }}
    position: absolute;
    top: -130px;
    left: -450px;
    z-index: -1;

    // &:lang(ar) {
    //   right: -420px;
    //   left: auto;
    // }

    @media only screen and (max-width: 1699px) {
      top: -130px;
      left: -325px;

      // &:lang(ar) {
      //   right: -330px;
      //   left: auto;
      // }
    }

    @media only screen and (max-width: 1440px) {
      top: -130px;
      left: -230px;

      // &:lang(ar) {
      //   right: -250px;
      //   left: auto;
      // }
    }

    @media only screen and (max-width: 1399px) {
      top: -110px;
      left: -300px;

      // &:lang(ar) {
      //   right: -250px;
      //   left: auto;
      // }
    }

    @media only screen and (max-width: 1199px) {
      top: -120px;
      left: -260px;

      // &:lang(ar) {
      //   right: -250px;
      //   left: auto;
      // }
    }

    @media only screen and (max-width: 991px) {
      top: -110px;
      left: -280px;

      // &:lang(ar) {
      //   right: -280px;
      //   left: auto;
      // }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }

  .left-circle-div {
    // style={{ position: 'relative', width: '500px', height: '500px', borderRadius: '50%', backgroundColor: '#899bdb', }}

    position: relative;
    width: 380px;
    height: 380px;
    border-radius: 50%;
    background-color: theme-color('gray8');

    @media only screen and (max-width: 1199px) {
      width: 330px;
      height: 330px;
    }

    @media only screen and (max-width: 991px) {
      width: 280px;
      height: 280px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .left-circle-details {
    // style={{ position: 'absolute', width: '400px', height: '400px', borderRadius: '50%', backgroundColor: 'white', top: '50px', left: '50px' }}
    position: absolute;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: white;
    top: 50px;
    left: 50px;


    @media only screen and (max-width: 1199px) {
      width: 230px;
      height: 230px;
    }

    @media only screen and (max-width: 991px) {
      width: 180px;
      height: 180px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }


  // -------------About Section --------------

  .about-title {
    text-align: start;

    &:lang(ar) {
      text-align: end;
    }
  }

  .about-description {
    text-align: start;

    &:lang(ar) {
      text-align: end;
    }
  }

  .left-about-circle {
    // style={{ position: 'absolute', bottom: -50, right: -250, zIndex:"-1" }}
    position: absolute;
    bottom: -80px;
    left: -110px;
    z-index: -1;

    &:lang(ar) {
      right: -75px;
      left: auto;
    }

    @media only screen and (max-width: 1699px) {
      bottom: -80px;
      left: -90px;

      &:lang(ar) {
        right: -70px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1440px) {
      bottom: -80px;
      left: -100px;

      &:lang(ar) {
        right: -75px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1399px) {
      bottom: -100px;
      left: -90px;

      &:lang(ar) {
        right: -75px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1199px) {
      bottom: -80px;
      left: -85px;

      &:lang(ar) {
        right: -70px;
        left: auto;
      }
    }

    @media only screen and (max-width: 991px) {
      bottom: -50px;
      left: -75px;

      &:lang(ar) {
        bottom: -60px;
        right: -85px;
        left: auto;
      }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }

  .left-about-circle-div {
    // style={{ position: 'relative', width: '500px', height: '500px', borderRadius: '50%', backgroundColor: '#899bdb', }}

    position: relative;
    width: 430px;
    height: 430px;
    border-radius: 50%;
    background-color: theme-color('primary');

    @media only screen and (max-width: 1199px) {
      width: 380px;
      height: 380px;
    }

    @media only screen and (max-width: 991px) {
      width: 330px;
      height: 330px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .left-about-circle-details {
    // style={{ position: 'absolute', width: '400px', height: '400px', borderRadius: '50%', backgroundColor: 'white', top: '50px', left: '50px' }}
    position: absolute;
    width: 330px;
    height: 330px;
    border-radius: 50%;
    background-color: white;
    top: 50px;
    left: 50px;


    @media only screen and (max-width: 1199px) {
      width: 280px;
      height: 280px;
    }

    @media only screen and (max-width: 991px) {
      width: 230px;
      height: 230px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }


  // ------------------

  .right-about-circle {
    // style={{ position: 'absolute', bottom: -50, right: -250, zIndex:"-1" }}
    position: absolute;
    bottom: 200px;
    right: -350px;
    z-index: -1;

    &:lang(ar) {
      left: -350px;
      right: auto;
    }

    @media only screen and (max-width: 1699px) {
      bottom: 200px;
      right: -250px;

      &:lang(ar) {
        left: -250px;
        right: auto;
      }
    }

    @media only screen and (max-width: 1440px) {
      bottom: 170px;
      right: -130px;

      &:lang(ar) {
        left: -130px;
        right: auto;
      }
    }

    @media only screen and (max-width: 1399px) {
      bottom: 150px;
      right: -180px;

      &:lang(ar) {
        left: -180px;
        right: auto;
      }
    }

    @media only screen and (max-width: 1199px) {
      bottom: 170px;
      right: -160px;

      &:lang(ar) {
        left: -160px;
        right: auto;
      }
    }

    @media only screen and (max-width: 991px) {
      bottom: 170px;
      right: -180px;

      &:lang(ar) {
        left: -180px;
        right: auto;
      }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }

  .right-about-circle-div {
    // style={{ position: 'relative', width: '500px', height: '500px', borderRadius: '50%', backgroundColor: '#899bdb', }}

    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: theme-color('gray8');

    @media only screen and (max-width: 1199px) {
      width: 100px;
      height: 100px;
    }

    @media only screen and (max-width: 991px) {
      width: 100px;
      height: 100px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .right-about-circle-details {
    // style={{ position: 'absolute', width: '400px', height: '400px', borderRadius: '50%', backgroundColor: 'white', top: '50px', left: '50px' }}
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    top: 15px;
    left: 15px;


    @media only screen and (max-width: 1199px) {
      width: 70px;
      height: 70px;
    }

    @media only screen and (max-width: 991px) {
      width: 70px;
      height: 70px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }




  // -------------------------------
  .div-lines {
    text-align: -webkit-center;
    padding-bottom: 50px;

    // padding-bottom: 37%;

    // @media only screen and (max-width: 1600px) {
    //   padding-bottom: 40%;
    // }

    // @media only screen and (max-width: 1440px) {
    //   padding-bottom: 50%;
    // }

    // @media only screen and (max-width: 1199px) {
    //   padding-bottom: 130%;
    // }

    // @media only screen and (max-width: 1024px) {
    //   padding-bottom: 135%;
    // }

    // @media only screen and (max-width: 991px) {
    //   padding-bottom: 160%;
    // }

    // @media only screen and (max-width: 820px) {
    //   padding-bottom: 168%;
    // }

    // @media only screen and (max-width: 767px) {
    //   padding-bottom: 375%;
    // }

    // @media only screen and (max-width: 715px) {
    //   padding-bottom: 410%;
    // }
  }

  .lines {
    position: relative;
    max-width: 75%;

    text-align: center;

    @media only screen and (max-width: 767px) {
      max-width: 85%;
    }

    @media only screen and (max-width: 575px) {
      max-width: 100%;
    }
  }

  .lines:before {
    content: " ";
    height: 5px;
    width: 43%;
    background-color: black;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;


    @media only screen and (max-width: 1199px) {
      width: 40%;
    }

    @media only screen and (max-width: 767px) {
      width: 38%;
    }

  }

  .lines::after {
    content: " ";
    height: 5px;
    width: 43%;
    background-color: black;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;

    @media only screen and (max-width: 1199px) {
      width: 40%;
    }

    @media only screen and (max-width: 767px) {
      width: 38%;
    }
  }


  // ------------------features---------------


  .features {
    padding-top: 150px;
    position: relative;
    z-index: 15;
  }

  @keyframes moveDownAndUp {
    0% {
      top: 180px;
    }

    50% {
      top: 220px;
    }

    100% {
      top: 180px;
    }
  }

  .features-right-img {
    background-image: url('../../../public/Images/iconLogo.svg');
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 180px;
    left: 50px;
    animation: moveDownAndUp 2s ease infinite;

    @media only screen and (max-width: 1399px) {
      left: 0px;
    }

    @media only screen and (max-width: 1199px) {
      width: 70px;
      height: 70px;
      left: 0px;
    }

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }



  // --------------under-features-------------

  .under-features {
    background-image: url('../../../public/Images/backgroundSchool1.svg');
    background-repeat: no-repeat;
    background-position: 100% -50%;
    background-size: 100% 111.5%;
    padding-bottom: 50px;
    z-index: -10;
    padding-top: 70px;

    @media only screen and (max-width: 1440px) {
      background-size: 100% 125%;
      background-position: 100% -24%;
    }


    @media only screen and (max-width: 1199px) {
      background-size: 100% 125%;
      background-position: 100% 35%;
      padding-bottom: 0px;
    }

    @media only screen and (max-width: 991px) {
      background-image: unset;
    }
  }


  .plans-warrap {
    background-image: url('../../../public/Images/backgroundSchool.svg');
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 200% 234%;
    padding-top: -50px;
    z-index: -6;

    @media only screen and (max-width: 1600px) {
      background-size: 200% 234%;
    }

    @media only screen and (max-width: 1550px) {
      background-size: 200% 234%;
    }

    @media only screen and (max-width: 1500px) {
      background-size: 200% 234%;
    }

    @media only screen and (max-width: 1450px) {
      background-size: 200% 234%;
    }

    @media only screen and (max-width: 1440px) {
      background-size: 200% 262%;
    }

    @media only screen and (max-width: 1399px) {
      background-size: 200% 236%;
    }

    @media only screen and (max-width: 1199px) {
      background-size: 79% 180%;
      padding-top: 50px;
    }

    @media only screen and (max-width: 1024px) {
      background-size: 93% 180%;
    }

    @media only screen and (max-width: 991px) {
      background-image: unset;
    }
  }

  // ------------------plans------------------


  .plans-details {
    position: absolute;
    height: 300px;
    background-color: theme-color('primary');
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .last-section {
    position: absolute;
    height: 100px;
    background: theme-color('black1');
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }


  // ------------Images ----------------------
  // ---- First Row ---------

  .first-row-first-img {
    position: relative;

    img {
      width: 340px;
      height: auto;

      @media only screen and (max-width: 767px) {
        width: 70%;
      }

      @media only screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .first-row-second-img {
    margin-bottom: 10px;
    position: relative;

    @media only screen and (max-width: 1440px) {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 1399px) {
      margin-bottom: -20px;
    }

    @media only screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 991px) {
      margin-bottom: -30px;
    }

    @media only screen and (max-width: 767px) {
      margin-bottom: unset;
    }


    .second_img {
      width: 100%;
      height: auto;

      @media only screen and (max-width: 767px) {
        width: 65%;
      }

      @media only screen and (max-width: 425px) {
        width: 80%;
      }
    }

    .wifi {
      position: absolute;
      width: 40px;
      left: 31.5%;
      top: 17%;

      @media only screen and (max-width: 1440px) {
        width: 25px;
        left: 32%;
        top: 17%;
      }

      @media only screen and (max-width: 1299px) {
        width: 25px;
        left: 32%;
        top: 17%;
      }

      @media only screen and (max-width: 1199px) {
        width: 30px;
        left: 32%;
        top: 18%;
      }

      @media only screen and (max-width: 991px) {
        width: 25px;
      }

      @media only screen and (max-width: 767px) {
        width: 30px;
        left: 38.5%;
        top: 17%;
      }

      @media only screen and (max-width: 576px) {
        width: 25px;
        left: 38%;
        top: 16%;
      }

      @media only screen and (max-width: 425px) {
        width: 15px;
        left: 36%;
        top: 20%;
      }

      @media only screen and (max-width: 320px) {
        width: 15px;
        left: 36%;
        top: 17%;
      }
    }


    .wifi2 {
      position: absolute;
      width: 40px;
      right: -8%;
      top: 26%;
      transform: rotate(90deg) !important;

      @media only screen and (max-width: 1440px) {
        width: 35px;
        right: -9%;
        top: 25%;
      }

      @media only screen and (max-width: 1299px) {
        width: 35px;
        right: -9%;
        top: 25%;
      }

      @media only screen and (max-width: 1199px) {
        width: 35px;
        right: -7%;
        top: 27%;
      }

      @media only screen and (max-width: 991px) {
        width: 30px;
        right: -8%;
        top: 27%;
      }

      @media only screen and (max-width: 767px) {
        width: 30px;
        right: 13%;
        top: 27%;
      }

      @media only screen and (max-width: 576px) {
        width: 30px;
        right: 11%;
        top: 26%;
      }

      @media only screen and (max-width: 425px) {
        width: 25px;
        right: 3%;
        top: 25%;
      }

      @media only screen and (max-width: 320px) {
        width: 18px;
        right: 3%;
        top: 26%;
      }

    }
  }

  .first-row-third-img {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: -50px;
    position: relative;

    @media only screen and (max-width: 1440px) {
      margin-bottom: -65px;
    }

    @media only screen and (max-width: 1399px) {
      margin-bottom: -71px;
    }

    @media only screen and (max-width: 1199px) {
      margin-bottom: 50px;
    }

    @media only screen and (max-width: 767px) {
      margin-top: 50px;
    }

    .img1 {
      width: 150px;
      height: auto;
      margin-right: -80px;
      z-index: 1;
      margin-bottom: -5px;

      @media only screen and (max-width: 1440px) {
        width: 120px;
        margin-right: -70px;
      }

      @media only screen and (max-width: 425px) {
        width: 150px;
        margin-right: -72px;
        margin-bottom: 0px;
      }

      @media only screen and (max-width: 375px) {
        width: 100px;
        margin-right: -48px;
      }
    }

    .img2 {
      width: 350px;
      height: auto;
      margin-bottom: -46px;
      z-index: 0;


      @media only screen and (max-width: 1440px) {
        width: 300px;
        margin-bottom: -42px;
      }

      @media only screen and (max-width: 425px) {
        width: 300px;
        margin-bottom: -35px;
      }

      @media only screen and (max-width: 375px) {
        width: 200px;
        margin-bottom: -23px;
      }



    }


    // ----car wifi ------

    .wifi-car {
      position: absolute;
      width: 30px;
      left: 12%;
      top: 0%;

      @media only screen and (max-width: 1440px) {
        width: 30px;
        left: 12%;
        top: 0%;
      }

      @media only screen and (max-width: 1199px) {
        width: 26px;
        left: 33.5%;
        top: 3%;
      }

      @media only screen and (max-width: 991px) {
        width: 25px;
        left: 30.5%;
        top: 8%;
      }

      @media only screen and (max-width: 767px) {
        width: 22px;
        left: 22%;
        top: 0%;
      }

      @media only screen and (max-width: 660px) {
        width: 22px;
        left: 22%;
        top: 8%;
      }


      @media only screen and (max-width: 576px) {
        width: 22px;
        left: 22%;
        top: 8%;
      }

      @media only screen and (max-width: 500px) {
        width: 22px;
        left: 18%;
        top: 0%;
      }

      @media only screen and (max-width: 425px) {
        width: 18px;
        left: 17%;
        top: 0%;
      }

      @media only screen and (max-width: 375px) {
        width: 18px;
        left: 20%;
        top: 0%;
      }

      @media only screen and (max-width: 320px) {
        width: 16px;
        left: 17%;
        top: 0%;
      }
    }
  }

  // ----- Left Image About -----
  .left-section {
    margin-left: -112px;

    @media only screen and (max-width: 1399px) {
      margin-left: -95px;
    }

    @media only screen and (max-width: 1199px) {
      margin-left: -80px;
    }

    @media only screen and (max-width: 991px) {
      margin-left: -70px;
    }

    @media only screen and (max-width: 767px) {
      margin-left: -92px;
    }

    @media only screen and (max-width: 576px) {
      margin-left: 0px;
    }
  }

  .warrap {
    width: 100px;
    height: 100px;
    transform: scaleX(1);
    right: 0px;
    position: absolute;
    top: 10px;
  }

  .warrap-details {
    width: 100%;
    height: 100%;
    animation: circle 5s infinite linear;
    transform-origin: 50% 50%;
  }

  .warrap-div {
    width: 80px;
    height: 80px;
    animation: ccircle 5s infinite linear;
  }

  .twist-animation {
    width: 300px;
    height: 100px;
    position: absolute;
    right: -100px;
    top: 0px;
    display: block;
    transform: scaleX(0.33);

    @media only screen and (max-width: 1399px) {
      top: -10px;
      right: -110px;
    }

    @media only screen and (max-width: 991px) {
      top: -20px;
      right: -110px;
    }

    @media only screen and (max-width: 425px) {
      top: -25px;
      right: -100px;
      width: 250px;
      height: 80px;
    }
  }

  // @keyframes twist {
  //   0% {
  //     transform: translate(0, 0) rotate(0deg);
  //   }

  //   25% {
  //     transform: translate(20px, 0) rotate(90deg);
  //   }

  //   50% {
  //     transform: translate(20px, 20px) rotate(180deg);
  //   }

  //   75% {
  //     transform: translate(0, 20px) rotate(270deg);
  //   }

  //   100% {
  //     transform: translate(0, 0) rotate(360deg);
  //   }
  // }


  @keyframes circle {
    from {
      transform: rotateZ(0deg)
    }

    to {
      transform: rotateZ(360deg)
    }
  }

  @keyframes ccircle {
    from {
      transform: rotateZ(360deg)
    }

    to {
      transform: rotateZ(0deg)
    }
  }

  // All Text Sizes

  // First Row Text

  .text1 {
    position: absolute;
    top: 21%;
    right: 26%;
    max-width: 21%;
    text-align: start;


    &:lang(ar) {
      max-width: 25%;
      text-align: end;
    }


    @media only screen and (max-width: 1440px) {
      top: 23%;
      right: 25%;
    }

    @media only screen and (max-width: 1199px) {
      top: 21%;
      right: 26%;
    }

    @media only screen and (max-width: 991px) {
      top: 22%;
      right: 26%;

      &:lang(ar) {
        right: 20%;
        max-width: 30%;
      }
    }

    @media only screen and (max-width: 767px) {
      top: 25%;
      right: 39%;
      max-width: 9%;
      font-size: 16px;

      &:lang(ar) {
        top: 32%;
        right: 26%;
      }
    }

    @media only screen and (max-width: 576px) {
      top: 26%;
      right: 38%;
      font-size: 14px;

      &:lang(ar) {
        top: 32%;
        right: 26%;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 25%;
      right: 38%;
      font-size: 13px;

      &:lang(ar) {
        top: 32%;
        right: 26%;
      }
    }

    @media only screen and (max-width: 375px) {
      top: 24%;
      right: 38%;
      font-size: 12px;

      &:lang(ar) {
        top: 30%;
        right: 22%;
      }
    }

    @media only screen and (max-width: 320px) {
      top: 22%;
      right: 37%;

      &:lang(ar) {
        max-width: 35%;
        top: 30%;
        right: 18%;
      }
    }
  }

  .text2 {
    position: absolute;
    top: 53%;
    left: 4%;
    max-width: 21%;
    text-align: start;
    font-size: 16px;


    &:lang(ar) {
      max-width: 28%;
      text-align: end;
      left: -6%;
      top: 51%;
    }

    @media only screen and (max-width: 1440px) {
      top: 53%;
      left: 4%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      top: 53%;
      left: 4%;
    }

    @media only screen and (max-width: 991px) {
      top: 53%;
      left: 4%;

      &:lang(ar) {
        max-width: 35%;
        left: -9%;
        top: 50%;
      }
    }

    @media only screen and (max-width: 767px) {
      top: 53%;
      left: 20%;
      font-size: 14px;

      &:lang(ar) {
        top: 50%;
        left: 12%;
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 576px) {
      top: 53%;
      left: 20%;
      font-size: 14px;

      &:lang(ar) {
        top: 50%;
        left: 11%;
        font-size: 13px;
      }
    }

    @media only screen and (max-width: 475px) {
      top: 53%;
      left: 20%;
      font-size: 13px;

      &:lang(ar) {
        top: 49%;
        left: 9%;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 53%;
      left: 13%;
      font-size: 13px;

      &:lang(ar) {
        top: 49%;
        left: 2%;
      }
    }

    @media only screen and (max-width: 375px) {
      top: 53%;
      left: 12%;
      font-size: 12px;

      &:lang(ar) {
        top: 49%;
        left: 0%;
      }
    }

    @media only screen and (max-width: 320px) {
      top: 52%;
      left: 11%;
      font-size: 11px;

      &:lang(ar) {
        top: 49%;
        left: 0%;

        font-size: 11px;
      }
    }
  }

  .text3 {
    position: absolute;
    bottom: 5%;
    right: -4%;
    max-width: 25%;
    text-align: start;
    font-size: 16px;

    &:lang(ar) {
      text-align: end;
      bottom: 10%;
      right: 0%;
    }

    @media only screen and (max-width: 1500px) {
      bottom: 4%;
      right: -3%;
      font-size: 18px;
    }

    @media only screen and (max-width: 1440px) {
      bottom: 13%;
      right: -12%;
      max-width: 32%;
      font-size: 15px;

      &:lang(ar) {
        bottom: 13%;
        right: 0%;
        max-width: 25%;
      }
    }

    @media only screen and (max-width: 1199px) {
      bottom: 12%;
      right: -12%;
      max-width: 32%;
    }

    @media only screen and (max-width: 991px) {
      bottom: 10%;
      right: -12%;
    }

    @media only screen and (max-width: 767px) {
      bottom: 5%;
      right: 10%;
      max-width: 22%;
      font-size: 16px;

      &:lang(ar) {
        max-width: 22%;
        bottom: 10%;
        right: 15%;
      }
    }

    @media only screen and (max-width: 576px) {
      bottom: 3%;
      right: 10%;
      font-size: 14px;

      &:lang(ar) {
        bottom: 10%;
        right: 10%;
      }
    }

    @media only screen and (max-width: 425px) {
      bottom: 5%;
      right: -3%;
      font-size: 13px;
      max-width: 30%;
    }

    @media only screen and (max-width: 375px) {
      bottom: 5%;
      right: -3%;
      font-size: 12px;

      &:lang(ar) {
        right: 5%;
      }
    }

    @media only screen and (max-width: 320px) {
      bottom: 5%;
      right: -3%;
    }
  }

  .text4 {
    position: absolute;
    top: 25%;
    left: 5%;
    max-width: 16%;
    text-align: start;
    font-size: 14px;

    &:lang(ar) {
      text-align: end;
    }

    @media only screen and (max-width: 1440px) {
      top: 27%;
      left: 10%;
    }

    @media only screen and (max-width: 1399px) {
      top: 27%;
      left: 3%;
    }

    @media only screen and (max-width: 1199px) {
      top: 30%;
      left: 32%;
      max-width: 7%;
      font-size: 13px;
    }

    @media only screen and (max-width: 991px) {
      top: 30%;
      left: 26%;
    }

    @media only screen and (max-width: 767px) {
      top: 30%;
      left: 18%;

      &:lang(ar) {
        top: 30%;
        left: 18%;
        max-width: 10%;
      }
    }

    @media only screen and (max-width: 660px) {
      top: 30%;
      left: 20%;

      &:lang(ar) {
        top: 30%;
        left: 18%;
      }
    }

    @media only screen and (max-width: 576px) {
      top: 28%;
      left: 20%;

      &:lang(ar) {
        top: 30%;
        left: 14%;
      }
    }

    @media only screen and (max-width: 500px) {
      top: 28%;
      left: 13%;

      &:lang(ar) {
        top: 28%;
        left: 9%;
        max-width: 15%;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 20%;
      left: 12%;
      font-size: 12px;

      &:lang(ar) {
        top: 21%;
        left: 9%;
        max-width: 15%;
      }
    }

    @media only screen and (max-width: 375px) {
      top: 28%;
      left: 7%;
      max-width: 50%;

      &:lang(ar) {
        top: 26%;
        left: 9%;
        max-width: 30%;
      }
    }

    @media only screen and (max-width: 320px) {
      top: 28%;
      left: 3%;

      &:lang(ar) {
        top: 26%;
        left: 4%;
      }
    }
  }

  .text5 {
    position: absolute;
    top: 5%;
    left: 28%;
    text-align: start;

    &:lang(ar) {
      text-align: end !important;
    }

    @media only screen and (max-width: 1440px) {
      top: 0%;
      left: 27%;
    }

    @media only screen and (max-width: 1199px) {
      top: 4%;
      left: 40%;
    }

    @media only screen and (max-width: 991px) {
      top: 4%;
      left: 37%;
    }

    @media only screen and (max-width: 767px) {
      top: 4%;
      left: 35%;
      font-size: 16px;
    }

    @media only screen and (max-width: 576px) {
      top: 5%;
      left: 32%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: 9%;
      left: 29%;
      font-size: 13px;
    }

    @media only screen and (max-width: 375px) {
      top: 2%;
      left: 33%;
      font-size: 12px;
    }

    @media only screen and (max-width: 320px) {
      top: 2%;
      left: 31%;
    }
  }

  // second Row Text 

  .text6 {
    position: absolute;
    top: 22%;
    left: 27%;
    text-align: end;
    max-width: 10%;
    font-size: 16px;

    &:lang(ar) {
      text-align: start !important;
      max-width: 5%;
      left: 31%;
    }


    @media only screen and (max-width: 1440px) {
      top: 22%;
      left: 27%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      top: 22%;
      left: 27%;
      font-size: 15px;
    }

    @media only screen and (max-width: 991px) {
      top: 22%;
      left: 25%;
      font-size: 13px;
    }

    @media only screen and (max-width: 767px) {
      top: 22%;
      left: 25%;
      font-size: 12px;
    }

    @media only screen and (max-width: 576px) {
      top: 22%;
      left: 20%;
      font-size: 11px;

      &:lang(ar) {
        top: 22%;
        left: 31%;
        text-align: start !important;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 22%;
      left: 20%;
      font-size: 10px;
    }

    @media only screen and (max-width: 375px) {
      top: 22%;
      left: 19%;
    }

    @media only screen and (max-width: 320px) {
      top: 15%;
      left: 17%;
    }
  }

  .text7 {
    position: absolute;
    top: 22%;
    right: 33%;
    text-align: start;
    max-width: 10%;
    font-size: 16px;

    &:lang(ar) {
      text-align: end !important;
      max-width: 7%;
      right: 35%;
    }

    @media only screen and (max-width: 1440px) {
      top: 22%;
      right: 33%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      top: 22%;
      right: 33%;
      font-size: 15px;
    }

    @media only screen and (max-width: 991px) {
      top: 22%;
      right: 33%;
      font-size: 13px;
    }

    @media only screen and (max-width: 767px) {
      top: 22%;
      right: 30%;
      font-size: 12px;

      &:lang(ar) {
        right: 30%;
        max-width: 10%;
      }
    }

    @media only screen and (max-width: 576px) {
      top: 22%;
      right: 30%;
      font-size: 11px;

      &:lang(ar) {
        text-align: start !important;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 20%;
      right: 30%;
      font-size: 10px;
    }

    @media only screen and (max-width: 375px) {
      top: 18%;
      right: 30%;

      &:lang(ar) {
        right: 30%;
        max-width: 15%;
      }
    }

    @media only screen and (max-width: 320px) {
      top: 15%;
      right: 30%;

      &:lang(ar) {
        max-width: 15%;
      }
    }
  }

  .text8 {
    position: absolute;
    bottom: 28%;
    right: 25%;
    text-align: start;
    max-width: 10%;
    font-size: 16px;

    &:lang(ar) {
      text-align: end !important;
      max-width: 7%;
      right: 29%;
    }

    @media only screen and (max-width: 1440px) {
      bottom: 28%;
      right: 25%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      bottom: 28%;
      right: 25.5%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      bottom: 28%;
      right: 25.5%;
      font-size: 12px;

      &:lang(ar) {
        right: 28%;
      }
    }

    @media only screen and (max-width: 767px) {
      bottom: 28%;
      right: 22%;
      font-size: 10px;

      &:lang(ar) {
        right: 24%;
        max-width: 8%;
      }
    }

    @media only screen and (max-width: 576px) {
      bottom: 28%;
      right: 22%;
    }

    @media only screen and (max-width: 425px) {
      bottom: 28%;
      right: 22%;
    }

    @media only screen and (max-width: 375px) {
      bottom: 28%;
      right: 22%;
    }

    @media only screen and (max-width: 320px) {
      bottom: 28%;
      right: 22%;
    }
  }

  // Third Row Text

  .text9 {
    position: absolute;
    top: -15%;
    left: 15%;
    text-align: start;
    font-size: 16px;


    @media only screen and (max-width: 1440px) {
      top: -13%;
      left: 10%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      top: -8%;
      left: 10%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -8%;
      left: 15%;
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      top: -2%;
      left: 10%;
    }

    @media only screen and (max-width: 576px) {
      top: -4%;
      left: 10%;
    }

    @media only screen and (max-width: 425px) {
      top: -8%;
      left: 10%;
    }

    @media only screen and (max-width: 375px) {
      top: -8%;
      left: 10%;
    }

    @media only screen and (max-width: 320px) {
      top: -8%;
      left: 10%;
    }
  }

  .text10 {
    position: absolute;
    top: -20%;
    left: 10%;
    text-align: start;
    font-size: 16px;

    @media only screen and (max-width: 1440px) {
      top: -18%;
      left: 10%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      top: -15%;
      left: 10%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -15%;
      left: 10%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: -5%;
      left: 10%;
    }

    @media only screen and (max-width: 576px) {
      top: -7%;
      left: 10%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: -10%;
      left: 10%;
      font-size: 12px;
    }

    @media only screen and (max-width: 375px) {
      top: -15%;
      left: 10%;
    }

    @media only screen and (max-width: 320px) {
      top: -12%;
      left: 10%;
    }
  }

  .text11 {
    position: absolute;
    top: 2%;
    left: 6%;
    text-align: start;
    max-width: 20%;
    font-size: 16px;

    &:lang(ar) {
      max-width: 35%;
      top: 15%;
      left: 18%;
    }

    @media only screen and (max-width: 1440px) {
      top: 7%;
      left: 6%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      top: 0%;
      left: 6%;

      &:lang(ar) {
        max-width: 50%;
      }
    }

    @media only screen and (max-width: 1199px) {
      top: 8%;
      left: 6%;
      font-size: 14px;

      &:lang(ar) {
        max-width: 30%;
      }
    }

    @media only screen and (max-width: 991px) {
      top: 15%;
      left: 15%;
      font-size: 16px;

      &:lang(ar) {
        top: 20%;
        left: 20%;
      }
    }

    @media only screen and (max-width: 767px) {
      top: 25%;
      left: 6%;
    }

    @media only screen and (max-width: 576px) {
      top: 22%;
      left: 6%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: 22%;
      left: 6%;
      font-size: 12px;
    }

    @media only screen and (max-width: 375px) {
      top: 20%;
      left: 6%;
    }

    @media only screen and (max-width: 320px) {
      top: 20%;
      left: 6%;
    }
  }

  .text12 {
    position: absolute;
    top: -28%;
    left: 5%;
    text-align: start;
    max-width: 50%;
    font-size: 16px;


    &:lang(ar) {
      top: -30%;
      left: -8%;
      max-width: 40%;
    }

    @media only screen and (max-width: 1440px) {
      top: -25%;
      left: 5%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      top: -30%;
      left: 5%;

      &:lang(ar) {
        max-width: 50%;
        top: -35%;
        left: -18%;
      }
    }

    @media only screen and (max-width: 1199px) {
      top: -22%;
      left: 5%;
      font-size: 14px;
      max-width: 40%;
    }

    @media only screen and (max-width: 991px) {
      top: -18%;
      left: 10%;
      font-size: 16px;
      max-width: 35%;

      &:lang(ar) {
        top: -10%;
        left: -5%;
      }
    }

    @media only screen and (max-width: 767px) {
      top: 0%;
      left: 5%;
      max-width: 35%;

      &:lang(ar) {
        top: -5%;
        left: 8%;
      }
    }

    @media only screen and (max-width: 576px) {
      top: -3%;
      left: 4%;
      font-size: 14px;
      max-width: 50%;
    }

    @media only screen and (max-width: 425px) {
      top: -3%;
      left: 4%;
      font-size: 12px;
    }

    @media only screen and (max-width: 375px) {
      top: -5%;
      left: 2%;
      font-size: 11px;
    }

    @media only screen and (max-width: 320px) {
      top: -10%;
      left: 0%;
    }
  }

  // Four Row Text

  .text13 {
    position: absolute;
    top: -27%;
    left: 7%;
    text-align: start;
    font-size: 16px;


    @media only screen and (max-width: 1440px) {
      top: -27%;
      left: 7%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 1199px) {
      top: -20%;
      left: 7%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -15%;
      left: 7%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: -8%;
      left: 7%;
    }

    @media only screen and (max-width: 576px) {
      top: -8%;
      left: 5%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: -8%;
      left: 5%;
      font-size: 12px;
    }

  }

  .text14 {
    position: absolute;
    top: -15%;
    left: 20%;
    text-align: start;
    font-size: 16px;


    @media only screen and (max-width: 1440px) {
      top: -12%;
      left: 20%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 1199px) {
      top: -8%;
      left: 20%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -5%;
      left: 10%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: -2%;
      left: 10%;
    }

    @media only screen and (max-width: 576px) {
      top: -3%;
      left: 6%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: -3%;
      left: 5%;
      font-size: 12px;
    }
  }

  .text15 {
    position: absolute;
    top: -8%;
    left: 5%;
    text-align: start;
    font-size: 16px;


    @media only screen and (max-width: 1440px) {
      top: -8%;
      left: 5%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 1199px) {
      top: -8%;
      left: 5%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -8%;
      left: 5%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: -2%;
      left: 5%;
    }

    @media only screen and (max-width: 576px) {
      top: -5%;
      left: 5%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: -5%;
      left: 5%;
      font-size: 12px;
    }
  }

  .text16 {
    position: absolute;
    top: -20%;
    left: 10%;
    text-align: start;
    font-size: 16px;

    @media only screen and (max-width: 1440px) {
      top: -15%;
      left: 10%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      top: -17%;
      left: 10%;
      font-size: 13px;
    }

    @media only screen and (max-width: 1199px) {
      top: -10%;
      left: 10%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -8%;
      left: 15%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: 2%;
      left: 0%;
    }

    @media only screen and (max-width: 576px) {
      top: 4%;
      left: 3%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: -5%;
      left: 2%;
      font-size: 12px;
    }
  }

  .text17 {
    position: absolute;
    top: -25%;
    left: 5%;
    text-align: start;
    font-size: 16px;

    @media only screen and (max-width: 1440px) {
      top: -25%;
      left: 5%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      top: -25%;
      left: 5%;
      font-size: 13px;
    }

    @media only screen and (max-width: 1199px) {
      top: -13%;
      left: 2%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: -10%;
      left: 3%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: -2%;
      left: 3%;
    }

    @media only screen and (max-width: 576px) {
      top: 5%;
      left: 3%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: -6%;
      left: 5%;
      font-size: 12px;
    }
  }

  // Five Row Text

  .text18 {
    position: absolute;
    top: 15%;
    right: -7%;
    text-align: start;


    &:lang(ar) {
      top: -2%;
      right: -41%;
      max-width: 70%;
      text-align: end;
    }

    @media only screen and (max-width: 1440px) {
      top: 15%;
      right: 1%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1399px) {
      top: 15%;
      right: -5%;
      font-size: 15px;

      &:lang(ar) {
        top: 0%;
        right: -40%;
      }
    }

    @media only screen and (max-width: 1199px) {
      top: 15%;
      right: -8%;
      font-size: 14px;

      &:lang(ar) {
        top: -5%;
        right: -30%;
        max-width: 60%;
      }
    }

    @media only screen and (max-width: 991px) {
      top: 15%;
      right: 5%;
      font-size: 16px;


      &:lang(ar) {
        top: 2%;
        right: -15%;
        max-width: 45%;
      }
    }

    @media only screen and (max-width: 767px) {
      top: 20%;
      right: 18%;


      &:lang(ar) {
        top: 15%;
        right: 0%;
        max-width: 30%;
      }
    }

    @media only screen and (max-width: 576px) {
      top: 20%;
      right: 14%;
      font-size: 14px;

      &:lang(ar) {
        top: 15%;
        right: 7%;
        max-width: 25%;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 18%;
      right: 8%;
      font-size: 12px;

      &:lang(ar) {
        top: 15%;
        right: 6%;
        max-width: 25%;
      }
    }

    @media only screen and (max-width: 375px) {
      top: 18%;
      right: 8%;
      font-size: 12px;

      &:lang(ar) {
        top: 13%;
        right: 0%;
        max-width: 30%;
      }
    }

    @media only screen and (max-width: 320px) {
      top: 18%;
      right: 8%;
      font-size: 12px;

      &:lang(ar) {
        top: 12%;
        right: -8%;
        max-width: 40%;
      }
    }
  }

  .text19 {
    position: absolute;
    top: 16%;
    right: 13%;
    text-align: start;

    &:lang(ar) {
      right: 0%;
    }

    @media only screen and (max-width: 1440px) {
      top: 16%;
      right: 18%;
      font-size: 15px;
    }

    @media only screen and (max-width: 1199px) {
      top: 16%;
      right: 15%;
      font-size: 14px;
    }

    @media only screen and (max-width: 991px) {
      top: 16%;
      right: 20%;
      font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
      top: 18%;
      right: 28%;

      &:lang(ar) {
        right: 20%;
      }
    }

    @media only screen and (max-width: 576px) {
      top: 18%;
      right: 25%;
      font-size: 14px;
    }

    @media only screen and (max-width: 425px) {
      top: 18%;
      right: 22%;
      font-size: 12px;
    }
  }


  // ---- Second Row ---------

  .second-row {
    position: relative;


    .part-img {
      width: 90%;
      height: auto;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }


    .family-img {
      position: absolute;
      left: 35%;
      width: auto;
      height: 85%;
      z-index: 10;
      top: 15%;

    }
  }




  // ------Left Image------

  .left-plans-circle {
    // style={{ position: 'absolute', bottom: -50, right: -250, zIndex:"-1" }}
    position: absolute;
    top: 60px;
    left: -80px;
    z-index: -1;

    &:lang(ar) {
      right: -80px;
      left: auto;
    }

    @media only screen and (max-width: 1699px) {
      top: 60px;
      left: -80px;

      &:lang(ar) {
        right: -80px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1440px) {
      top: 60px;
      left: -80px;

      &:lang(ar) {
        right: -80px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1399px) {
      top: 60px;
      left: -80px;

      &:lang(ar) {
        right: -80px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1199px) {
      top: 60px;
      left: -60px;

      &:lang(ar) {
        right: -60px;
        left: auto;
      }
    }

    @media only screen and (max-width: 991px) {
      top: 60px;
      left: -45px;

      &:lang(ar) {
        right: -45px;
        left: auto;
      }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }

  .left-plans-circle-div {
    // style={{ position: 'relative', width: '500px', height: '500px', borderRadius: '50%', backgroundColor: '#899bdb', }}

    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-color: theme-color('gray8');

    @media only screen and (max-width: 1199px) {
      width: 130px;
      height: 130px;
    }

    @media only screen and (max-width: 991px) {
      width: 100px;
      height: 100px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .left-plans-circle-details {
    // style={{ position: 'absolute', width: '400px', height: '400px', borderRadius: '50%', backgroundColor: 'white', top: '50px', left: '50px' }}
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: white;
    top: 20px;
    left: 20px;


    @media only screen and (max-width: 1199px) {
      width: 90px;
      height: 92px;
    }

    @media only screen and (max-width: 991px) {
      width: 62px;
      height: 62px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  // ------Right Image------

  .right-plans-circle {
    position: absolute;
    bottom: 130px;
    right: 100px;
    z-index: -10;

    &:lang(ar) {
      left: 25px;
      right: auto;
    }

    @media only screen and (max-width: 1699px) {
      bottom: 130px;
      right: 25px;

      &:lang(ar) {
        left: 25px;
        right: auto;
      }
    }

    @media only screen and (max-width: 1440px) {
      bottom: 130px;
      right: 25px;

      &:lang(ar) {
        left: 25px;
        right: auto;
      }
    }

    @media only screen and (max-width: 1399px) {
      bottom: 130px;
      right: 25px;

      &:lang(ar) {
        left: 25px;
        right: auto;
      }
    }

    @media only screen and (max-width: 1199px) {
      bottom: 130px;
      right: 25px;

      &:lang(ar) {
        left: 25px;
        right: auto;
      }
    }

    @media only screen and (max-width: 991px) {
      bottom: 190px;
      right: 25px;

      &:lang(ar) {
        left: 25px;
        right: auto;
      }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }

  .right-plans-circle-div {
    position: relative;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background-color: theme-color('gray8');

    @media only screen and (max-width: 1199px) {
      width: 300px;
      height: 300px;
    }

    @media only screen and (max-width: 991px) {
      width: 250px;
      height: 250px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .right-plans-circle-details {
    position: absolute;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: white;
    top: 36px;
    left: 35px;


    @media only screen and (max-width: 1199px) {
      width: 230px;
      height: 230px;
    }

    @media only screen and (max-width: 991px) {
      width: 180px;
      height: 180px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  // ------Left Image------

  .left-plans-small-circle {
    position: absolute;
    bottom: 20px;
    left: 180px;
    z-index: 1;

    &:lang(ar) {
      right: 50px;
      left: auto;
    }

    @media only screen and (max-width: 1699px) {
      bottom: 30px;
      left: 85px;

      &:lang(ar) {
        right: 50px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1440px) {
      bottom: 30px;
      left: 20px;

      &:lang(ar) {
        right: 50px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1399px) {
      bottom: 30px;
      left: 20px;

      &:lang(ar) {
        right: 20px;
        left: auto;
      }
    }

    @media only screen and (max-width: 1199px) {
      bottom: 30px;
      left: 20px;

      &:lang(ar) {
        right: 20px;
        left: auto;
      }
    }

    @media only screen and (max-width: 991px) {
      bottom: 50px;
      left: 70px;

      &:lang(ar) {
        right: 20px;
        left: auto;
      }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

  }

  .left-plans-small-circle-div {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: rgb(204, 204, 204);
    opacity: 0.3;

    @media only screen and (max-width: 1199px) {
      width: 120px;
      height: 120px;
    }

    @media only screen and (max-width: 991px) {
      width: 100px;
      height: 100px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .left-plans-small-circle-details {
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: theme-color('primary');
    top: 15px;
    left: 15px;


    @media only screen and (max-width: 1199px) {
      width: 90px;
      height: 92px;
    }

    @media only screen and (max-width: 991px) {
      width: 70px;
      height: 70px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  // ----------------------


  .card1 {
    @media only screen and (max-width: 767px) {
      padding-top: 30px;
    }
  }

  .card2 {
    @media only screen and (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .card3 {
    @media only screen and (max-width: 991px) {
      padding-top: 100px;
    }
  }

  .card4 {
    @media only screen and (max-width: 1199px) {
      padding-top: 100px;
    }
  }






  .row4-image1 {
    width: 70%;
    height: auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .row4-image2 {
    width: 85%;
    height: auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .row4-image3 {
    width: 117%;
    height: auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .row4-image4 {
    width: 75%;
    height: auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }


  // ------------------------

  .row5-image3 {
    margin-bottom: -10px;
  }

  .row5-image4 {
    width: 80%;
    height: auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .row5-image5 {
    width: 110%;
    height: auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
}