@import "assets/styles/variables";


.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.divider {
    height: 2px;
    background-color: theme-color("primary");
    width: 58%;


    @media only screen and (max-width: 1199px) {
      width: 78%;
    }

    @media only screen and (max-width: 991px) {
      width: 95%;
    }

    @media only screen and (max-width: 575px) {
      width: 70%;
    }

    @media only screen and (max-width: 375px) {
      width: 85%;
    }

  }