@import "assets/styles/variables";


.custom-card {
  position: relative;
  margin-top: 35px;

  .custom-card-style {
    min-height: 800px;
    text-align: center;
    align-items: center;
    border: 2px solid theme-color("gray6");
    z-index: 100;
    // margin: 30px;

    // @media only screen and (max-width: 1550px) {
    //   margin: 20px;
    // }

    // @media only screen and (max-width: 1440px) {
    //   margin: 0px;
    // }

    // @media only screen and (max-width: 767px) {
    //   margin: 0 100px;
    // }

    // @media only screen and (max-width: 576px) {
    //   margin: 0;
    // }
  }

  .custom-card-details {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -90px;
    left: 17%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    text-align-last: center;


    // .title {}

    .price {
      letter-spacing: 6px;
      font-family: system-ui;
    }

    .unber-price {
      font-size: 12px;
    }

    @media only screen and (max-width: 1440px) {
      left: 17%;
    }

    @media only screen and (max-width: 1399px) {
      left: 12%;
    }

    @media only screen and (max-width: 1199px) {
      left: 16%;
    }

    @media only screen and (max-width: 991px) {
      left: 20%;
    }

    @media only screen and (max-width: 767px) {
      left: 30%;
    }

    @media only screen and (max-width: 620px) {
      left: 25%;
    }

    @media only screen and (max-width: 576px) {
      left: 30%;
    }

    @media only screen and (max-width: 475px) {
      left: 26%;
    }

    @media only screen and (max-width: 425px) {
      left: 25%;
    }

    @media only screen and (max-width: 375px) {
      left: 22%;
    }

    @media only screen and (max-width: 320px) {
      left: 17%;
    }
  }


  .custom-card-body {
    text-align: start;
    padding: 80px 0 40px 0;
    line-height: 200%;
    min-width: 245px;
  }

  .custom-card-button:hover,
  .custom-card-style:hover {
    border: 2px solid theme-color("primary") !important;
  }

  .custom-card-button {
    width: 150px;
    height: 50px;
    position: absolute;
    bottom: -25px;
    left: 25%;
    border: 2px solid theme-color("gray6");
    background-color: theme-color("white") !important;
    color: black !important;
    z-index: 100;

    &:hover {
      border: 2px solid theme-color("primary");
      background-color: theme-color("primary") !important;
      color: theme-color("white") !important;
    }

    span {
      font-size: 15px;
      font-weight: bolder !important;
    }

    @media only screen and (max-width: 1440px) {
      left: 22%;
    }

    @media only screen and (max-width: 1399px) {
      left: 22%;
    }

    @media only screen and (max-width: 1199px) {
      left: 27%;
    }

    @media only screen and (max-width: 991px) {
      left: 28%;
    }

    @media only screen and (max-width: 767px) {
      left: 36%;
    }

    @media only screen and (max-width: 475px) {
      left: 32%;
    }

    @media only screen and (max-width: 425px) {
      left: 32%;
    }

    @media only screen and (max-width: 375px) {
      left: 26%;
    }
  }
}