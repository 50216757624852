.flip {
    &:not(-x, -y) {
      &:lang(rtl) {
        transform: scale(-1);
      }
    }
    &-x {
      &:lang(rtl) {
        transform: scaleX(-1);
      }
    }
    &-y {
      &:lang(rtl) {
        transform: scaleY(-1);
      }
    }
  }
  
  .text-sm-end {
    text-align: left;
    &:lang(rtl) {
      text-align: right;
    }
    @include media-breakpoint-up(sm) {
      text-align: right;
      &:lang(rtl) {
        text-align: left;
      }
    }
  }
  
  .text-lg-end {
    text-align: left;
    &:lang(rtl) {
      text-align: right;
    }
    @include media-breakpoint-up(lg) {
      text-align: right;
      &:lang(rtl) {
        text-align: left;
      }
    }
  }
  
  .break-word {
    word-break: break-word;
  }
  
  ul {
    list-style: none;
  }
  
  .text-overflow {
    &-1 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-3 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-4 {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-5 {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-6 {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-7 {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-8 {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-9 {
      display: -webkit-box;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-10 {
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.17) !important;
  }
  .modal-video-body {
    padding: 0 !important;
  
    .modal-video-close-btn {
      display: none !important;
    }
  
    .modal-video-inner {
      min-width: 70vw !important;
      max-width: calc(100%) !important;
      height: auto !important;
    }
  }
  
  .last-price {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: calc(100%);
      height: 1px;
      background: #ff7676;
      left: 0;
      top: 50%;
      bottom: 50%;
      transform: rotate(15deg);
    }
  }
  
  .section-mt {
    margin-top: 50px !important;
    @include media-breakpoint-up(lg) {
      margin-top: 120px !important;
    }
  }
  .section-mt {
    margin-top: 50px !important;
  
    @include media-breakpoint-up(lg) {
      margin-top: 120px !important;
    }
  }
  
  .strikethrough {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      right: 0;
      border-top: 1px solid #ff7676;
      width: 100%;
  
      -webkit-transform: rotate(8deg);
      -moz-transform: rotate(8deg);
      -ms-transform: rotate(8deg);
      -o-transform: rotate(8deg);
      transform: rotate(8deg);
    }
  }
  @include media-breakpoint-down(sm) {
    .px-xs {
      &-1 {
        padding-left: 4px;
        padding-right: 4px;
      }
      &-2 {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  
  .text-nowrap {
    white-space: nowrap;
  }
  
  .invert {
    &:lang(rtl) {
      transform: scaleX(-1);
    }
  }
  
  $font-size: (63px, 40px, 36px, 27px, 21px, 18px, 17px, 16px, 14px, 12px);
  
  @for $i from 1 through 10 {
    .fs-#{$i} {
      font-size: nth($font-size, $i) !important;
    }
  }
  