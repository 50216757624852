@import "assets/styles/variables";


#Footer {
    background-color: theme-color('black1');
    display: flex;
    align-items: center;
    padding: 70px 0 50px 0;

    @media only screen and (max-width: 575px) {
        height: 430px;
    }

    @media only screen and (max-width: 425px) {
        height: 400px;
    }


    .footer-details {
        text-align: start;

        @media only screen and (max-width: 575px) {
            text-align: center;
        }
    }

    .img {
        width: 12%;

        @media only screen and (max-width: 1199px) {
            width: 15%;
        }

        @media only screen and (max-width: 991px) {
            width: 25%;
        }

        @media only screen and (max-width: 767px) {
            width: 30%;
        }

        @media only screen and (max-width: 575px) {
            width: 30%;
        }
    }



    .hover:hover {
        color: theme-color("primary");
    }


    .icons-style {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}