@import "assets/styles/variables";


#header {
  background-color: rgb(44, 48, 58);
  max-width: 100%;
  position: relative;
  transition: background-color 0.3s ease-in-out;


  .logo-img {
    width: 93px;
    height: auto;
  }

  .navbar {
    z-index: 10;

    .hover:hover {
      color: theme-color("primary");
    }

    @media screen and (max-width: 991px) {

      .navbar-toggler {
        color: white;
        background-color: white;
      }

      .navbar-collapse {
        position: absolute;
        top: 50px;
        width: 155px;
        z-index: 10;
        color: #ffffff;

        .nav-link {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      #basic-navbar-nav {
        background-color: #ffffff;
        border: 1px solid #ccc;
        color: #ffffff;
        z-index: 10;
      }
    }
  }

  .header-navigation {
    .navbar-nav {
      a {
        font-size: 18px;
        font-weight: 700;
        color: rgb(255, 255, 255);
        z-index: 10;
        text-align: start;

        @media only screen and (max-width: 991px) {
          color: black;
        }
      }
    }
  }

  .lang {
    z-index: 100;
    text-align: center;
  }

  .langButton {

    Button {
      padding: 7px 15px 7px 15px;
    }

    &:lang(ar) {
      float: left;

      Button {
        padding: 1px 15px 4px 15px;
      }
    }
  }

  &.fixed-header {
    position: fixed;
    top: 0;
    background-color: theme-color('primary');
    width: 100%;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out;

    .navbar {
      z-index: 10;

      .hover:hover {
        color: theme-color("star");
      }

      @media screen and (max-width: 991px) {

        .navbar-toggler {
          color: white;
          background-color: white;
        }

        .navbar-collapse {
          position: absolute;
          top: 50px;
          width: 155px;
          z-index: 10;
          color: #ffffff;

          .nav-link {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }

        #basic-navbar-nav {
          background-color: #ffffff;
          border: 1px solid #ccc;
          color: #ffffff;
          z-index: 10;
        }
      }
    }

    .header-navigation {
      .navbar-nav {
        a {
          font-size: 18px;
          font-weight: 700;
          color: black;
          z-index: 10;
          text-align: start;

          @media only screen and (max-width: 991px) {
            color: black;
          }
        }
      }
    }

    .lang {
      z-index: 100;
      text-align: center;
    }

    .langButton {

      Button {
        padding: 7px 15px 7px 15px;
      }

      &:lang(ar) {
        float: left;

        Button {
          padding: 1px 15px 4px 15px;
        }
      }
    }
  }
}