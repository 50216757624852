@font-face {
    font-family: "vip-rawy";
    font-weight: 100;
    src: local("vip-rawy"),
      url("../../fonts/vip-rawy/alfont_com_AlFont_com_VIP-Rawy-Thin.otf")
        format("truetype");
  }
  
  @font-face {
    font-family: "vip-rawy";
    font-weight: 300;
    src: local("vip-rawy"),
      url("../../fonts/vip-rawy/alfont_com_AlFont_com_4_47.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: "vip-rawy";
    font-weight: 500;
    src: local("vip-rawy"),
      url("../../fonts/vip-rawy/alfont_com_AlFont_com_4_48.ttf")
        format("truetype");
  }
  
  // @font-face {
  //   font-family: "Antipasto";
  //   font-weight: 700;
  //   src: local("Antipasto"),
  //     url("../../fonts/Antipasto/AntipastoPro-Bold_trial.ttf")
  //       format("truetype");
  // }

  // @font-face {
  //   font-family: "vip-rawy";
  //   font-weight: 900;
  //   src: local("vip-rawy"),
  //     url("../../fonts/Antipasto/AntipastoPro-ExtraBold_trial.ttf")
  //       format("truetype");
  // }
  
  * {
    font-family: "vip-rawy";
    font-weight: 500 !important;
  }
  
  .fw-bolder {
    font-weight: 900;
  }
  .fw-bold {
    font-weight: 700;
  }
  .fw-normal {
    font-weight: 500;
  }
  .fw-light {
    font-weight: 300;
  }
  .fw-lighter {
    font-weight: 100;
  }
  
  html,
  body,
  #root {
    overflow-x: hidden;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    position: static;
    min-height: 100vh;
    width: 100%;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span,
  input,
  button,
  select,
  optgroup,
  textarea {
    line-height: unset !important;
    margin-bottom: 0;
  }
  
  
  
  .w-fit {
    max-width: fit-content !important;
  }
  